<template>
  <div class="page">
    <el-main>
      <el-container>
        <el-row>
          <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form :inline="true">
              <el-form-item>
                <el-select
                  v-model="personType"
                  placeholder="请选择人员类型"
                  style="width: 100px"
                  @change="personChange"
                  size="medium"
                >
                  <el-option
                    v-for="(item, i) in personTypeData"
                    :key="i"
                    :label="item.title"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item  v-show="personType == 0">
                <el-select
                  v-model="selectClass"
                  multiple
                  filterable
                  collapse-tags
                  placeholder="班级"
                  style="width: 220px"
                  @change="SearchPage()"
                  size="medium"
                >
                  <el-option
                    v-for="(item, i) in selectClassData"
                    :key="i"
                    :label="item.ClassName"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item  v-show="personType == 0">
                <el-select
                  v-model="selectStudentType"
                  multiple
                  filterable
                  collapse-tags
                  placeholder="学生类型"
                  style="width: 180px"
                  @change="SearchPage()"
                  size="medium"
                >
                  <el-option
                    v-for="(item, i) in selectStudentTypeData"
                    :key="i"
                    :label="item.title"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-select
                  v-model="selectCheckStatus"
                  multiple
                  filterable
                  collapse-tags
                  placeholder="考勤状态"
                  style="width: 150px"
                  @change="SearchPage()"
                  size="medium"
                >
                  <el-option
                    v-for="(item, i) in selectCheckStatusData"
                    :key="i"
                    :label="item.title"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-input
                v-model="keyword"
                placeholder="请输入关键字查询"
                class="input-with-select"
                style="width: 300px; margin-right: 10px"
                size="medium"
              >
                <template #prepend>
                  <el-select
                    v-model="checkInput"
                    multiple
                    collapse-tags
                    placeholder="请选择"
                    style="width: 120px"
                  >
                    <el-option
                      v-for="(item, i) in dataTableSearch"
                      :key="i"
                      :label="item.label"
                      :value="item.attr"
                    ></el-option>
                  </el-select>
                </template>
              </el-input>
              <el-form-item label="">
                <el-date-picker
                  v-model="selectDate"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  size="medium"
                >
                </el-date-picker>
              </el-form-item>
              <slot name="searchSlot"></slot>
              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  size="medium"
                  @click="SearchPage()"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-popover placement="bottom" :width="400" trigger="click">
                  <template #reference>
                    <el-button size="medium"
                      ><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button
                    >
                  </template>
                  <el-checkbox-group v-model="dataTableSearchList">
                    <div v-for="(item, i) in dataTableList" :key="i">
                      <el-checkbox
                        v-if="item.visible != false"
                        :label="item"
                        checked
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                      <el-checkbox
                        v-else
                        :label="item"
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                    </div>
                  </el-checkbox-group>
                  <el-button
                    size="small"
                    type="text"
                    @click="filterFunHandle('allchecked')"
                    >全选</el-button
                  >
                  <el-button size="small" type="text" @click="filterFunHandle('cancel')"
                    >取消全选</el-button
                  >
                </el-popover>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-table
              size="small"
              border
              ref="multipleTable"
              :data="dataTable"
              style="width: 99%"
              max-height="700"
              :height="tableHeight"
              :fit="true"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                fixed="left"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column
                v-for="(col, index) in dataTableSearchList"
                :key="index"
                sortable
                header-align="center"
                align="center"
                :prop="col.attr"
                :label="col.label"
              >
                <template #default="scope">
                  <el-image
                    v-if="col.type == 'img'"
                    :src="scope.row[col.attr]"
                    :preview-src-list="[scope.row[col.attr]]"
                    fit="fill"
                    style="width: 40px; height: 40px"
                  ></el-image>
                  <el-tag size="medium" v-else-if="col.type == 'enum'">
                    {{ col.formatters(scope.row[col.attr]) }}
                  </el-tag>

                  <el-image
                    v-else-if="col.type == 'custom'"
                    style="width: 50px; height: 50px; margin-left: 5px"
                    v-for="ite in scope.row[col.attr]"
                    :key="ite"
                    :src="ite.FaceUrl"
                    :preview-src-list="[ite.FaceUrl]"
                    lazy
                  ></el-image>

                  <label v-else>{{ scope.row[col.attr] }}</label>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <paging
              :currentPage="currentPage"
              :pagesize="pageSize"
              :total="total"
              @sizeChange="handleSizeChange"
              @currentChange="handleCurrentChange"
              :pagesizes="pageSizes"
            />
          </el-col>
        </el-row>
      </el-container>
    </el-main>
  </div>
</template>
<script>
let _this;
export default {
  name: "checks",
  components: {},
  data() {
    return {
      personType: 0,
      personTypeData: [
        { title: "学生", value: 0 },
        { title: "教职工", value: 1 },
      ],
      selectStudentType: [],
      selectStudentTypeData: [
        { title: "走读生", value: 0 },
        { title: "半走读", value: 1 },
        { title: "住校生", value: 2 },
      ],
      selectCheckStatus: [],
      selectCheckStatusData: [
        { title: "进", value: 0 },
        { title: "出", value: 1 },
        { title: "已打卡", value: 2 },
      ],
      selectClass: [],
      selectClassData: [],
      selectDate: "",
      total: 0, //数据总条数
      pageSize: 10, //当前显示数据数量
      pageSizes: [10, 20, 50, 100],
      currentPage: 1, //分页当前页
      keyword: "", //搜索输入关键字
      checkInput: "", //选择所需要查询的内容
      dataConfig: [
        {
          label: "Id",
          attr: "Id",
          isInput: false,
          isDisplay: false,
        },
        {
          label: "姓名",
          attr: "PersonName",
          isSearch: true,
        },
         {
          label: "人员类型",
          attr: "PersonType",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "学生";
                break;
              case 1:
                result = "教职工";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "班级",
          attr: "PersonDepName",
        },
        {
          label: "考勤状态",
          attr: "CheckStatus",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "进";
                break;
              case 1:
                result = "出";
                break;
              case 2:
                result = "已打卡";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "考勤照片1",
          attr: "CheckImgOneUrl",
          type: "img",
        },
        {
          label: "考勤照片2",
          attr: "CheckImgTwoUrl",
          type: "img",
        },
        {
          label: "考勤时间",
          attr: "SignTime",
        },
        {
          label: "考勤设备",
          attr: "Device",
          isSearch: true,
        },
         {
          label: "体温",
          attr: "SignTemperature",
          visible: false,
        },
        {
          label: "备注",
          attr: "RemarkInfo",
          isSearch: true,
          visible: false,
        },
        {
          label: "接收时间",
          attr: "CreatedTime",
          visible: false,
        },
      ],
      dataTableSearch: [], //搜索信息
      dataTableList: [], //显示列信息
      dataTableSearchList: [], //列筛选信息
      dataTable: [], //显示数据
      multipleSelection: [], //选中数据
      tableHeight: 200, //table高度
      groupData: [],
      groupGuid: "",
      passTimes: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      permissionTime: "",
      personGuids: [],
      authText: "",
    };
  },
  created() {
    _this = this;
  },
  mounted() {
    _this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      //设置table位置
      let heightStyle = window.innerHeight - 230;
      _this.tableHeight = heightStyle;

      //窗口大小改变时，table 高度设置
      window.onresize = () => {
        return (() => {
          let heightStyle = window.innerHeight - 230;
          _this.tableHeight = heightStyle;
        })();
      };
    });
    _this.initSelectClassList();
    _this.SearchPage();
    _this.dataTableSearch = _this.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
    _this.dataTableList = _this.dataConfig.filter((it) => it.isDisplay != false); //可显示列
  },
  methods: {
    //初始化班级选择数据
    initSelectClassList() {
      _this.$API.Classes.GetClassesDataList.get().then((res) => {
        _this.selectClassData = res;
      });
    },

    personChange() {
      if (_this.personType == 1) {
        _this.selectClass = [];
        _this.selectStudentType = [];
      }
      _this.SearchPage();
    },

    //页面搜索查询
    SearchPage() {
      let json = {
        searchValue: "",
        pageNo: _this.currentPage,
        pageSize: _this.pageSize,
        sortField: "",
        sortOrder: "",
        descStr: "",
        CheckType:0,
        ClassIds: _this.selectClass,
        SelectCheckStatus: _this.selectCheckStatus,
        PersonType: _this.personType,
        StudentTypes: _this.selectStudentType,
      };
      if (_this.checkInput.length > 0 && _this.keyword != "") {
        var searchParameters = [],
          input = _this.keyword;
        for (let index = 0; index < _this.checkInput.length; index++) {
          const element = _this.checkInput[index];
          var searchParametersItem = {
            field: element,
            op: 8,
            value: input,
            orGroup: "",
          };
          searchParameters.push(searchParametersItem);
        }
        json.searchParameters = searchParameters;
      }

      if (_this.selectDate && _this.selectDate.length == 2) {
        json.searchBeginTime = _this.selectDate[0];
        json.searchEndTime = _this.selectDate[1];
      }

      _this.$API.SchoolChecks.QueryByPage.post(json).then((res) => {
        if (res) {
          _this.total = res.TotalRows;
          _this.pagesize = res.PageSize;
          _this.currentPage = res.PageNo;
          _this.dataTable = res.Rows;
        }
      });
    },
    handleSizeChange(val) {
      _this.pageSize = val;
      _this.SearchPage();
    },
    handleCurrentChange(val) {
      _this.currentPage = val;
      _this.SearchPage();
    },
    handleSelectionChange(val) {
      _this.multipleSelection = val;
    },
    // 列筛选
    filterFunHandle(type, currentItem, index) {
      if (type === "allchecked") {
        // 全选
        _this.dataTableSearchList = _this.dataConfig.filter(
          (it) => it.isDisplay != false
        );
      } else if (type === "cancel") {
        // 取消全选
        _this.dataTableSearchList = []; // 复选框置为空，全部不选择
      }
    },
  },
};
</script>
<style scoped>
.page {
  margin: 5px;
  padding: 20px;
  min-height: 840px;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  width: auto;
}
.drawerTitle {
  color: #409eff;
}
.stime {
  width: 120px;
}
</style>
